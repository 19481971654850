<template>
  <base-section id="heading-separator" class="secondary ma-0 pa-0">
    <v-responsive
      class="ma-0 pa-0"
      max-width="2500"
      :max-height="$vuetify.breakpoint.mdAndUp ? 400 : 1000"
    >
      <div class="gradient-overlay">
        <base-img
          contain
          :src="require('@/assets/background-textured.webp')"
          class="image"
        >
          <v-container fluid class="white--text py-10">
            <v-row justify="center">
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? 10 : 12">
                <base-title
                  :space="$vuetify.breakpoint.mdAndUp ? 0 : 3"
                  class="white--text py-5"
                  :style="styles"
                  :title="title"
                  size="text-h4"
                />

                <base-body space="5">
                  <div v-html="div"></div>
                </base-body>
              </v-col>
            </v-row>
          </v-container>
        </base-img>
      </div>
    </v-responsive>
  </base-section>
</template>

<script>
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";
export default {
  name: "HeadingSeparator",
  computed: {
    gradient() {
      const color = `${this.$vuetify.theme.themes.light.secondary}`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    div: {
      type: String,
      required: true,
    },
    space: String,
    styles: String,
  },
};
</script>
<style scoped>
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.image {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.gradient-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(56, 56, 56);
}
</style>
